import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/dropdowns/dropdowns.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import DropdownBasicPreview from "../../../library/components/dropdown/react/_preview/basic"
import DropdownIconPreview from "../../../library/components/dropdown/react/_preview/icon"

import DropdownHeroComponent from "../../../library/components/dropdown/react/_preview/DropdownHeroComponent"

// All
const DropdownBasicJsx = require("!!raw-loader!../../../library/components/dropdown/react/basic.jsx")
const DropdownBasicHTML = require("!!raw-loader!../../../library/components/dropdown/html/basic.html")

const DropdownIconJsx = require("!!raw-loader!../../../library/components/dropdown/react/icon.jsx")
const DropdownIconHTML = require("!!raw-loader!../../../library/components/dropdown/html/icon.html")

const DropdownsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "DropdownBasic",
      title: "Basic Dropdown",
      active_tab: 1,
    },
    {
      component_name: "DropdownIcon",
      title: "Wth Icons",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Dropdowns - WindUI Component Library"
        ogtitle="Tailwind CSS Dropdowns - WindUI Component Library"
        description="Dropdown menu components allow users to make a selection from multiple options, and are less prominent from other controls. Built with Tailwind CSS by WindUI."
        ogdescription="Dropdown menu components allow users to make a selection from multiple options, and are less prominent from other controls. Built with Tailwind CSS by WindUI."
        url="components/dropdowns/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Dropdowns, Dropdowns, Dropdown Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Dropdowns</h1>
          <p>
            Menus allow users to make a selection from multiple options. They
            are less prominent and take up less space than selection controls,
            such as a set of radio buttons.
          </p>

          <div className="flex h-[35rem] w-full justify-start rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 md:justify-center md:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <DropdownHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic Dropdown Section */}
          <h3 id="DropdownBasic">
            Basic Dropdown <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="DropdownBasic"
            HtmlComponent={DropdownBasicHTML.default}
            JsxComponent={DropdownBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <DropdownBasicPreview
                DropdownBasic={
                  activeTabs[0].active_tab === 1
                    ? DropdownBasicHTML.default
                    : DropdownBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Icons Dropdown Section */}
          <h3 id="DropdownIcon">
            Dropdown with Icons <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="DropdownIcon"
            HtmlComponent={DropdownIconHTML.default}
            JsxComponent={DropdownIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-start rounded-xl bg-white p-8 md:justify-center md:p-16"
            >
              <DropdownIconPreview
                DropdownIcon={
                  activeTabs[1].active_tab === 1
                    ? DropdownIconHTML.default
                    : DropdownIconJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use a dropdown menu to display a list of choices on a temporary
            surface. They appear when users interact with a button, action, or
            other control.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Dropdowns require a trigger (usually a <code>&lt;button&gt;</code>{" "}
            element). They are implemented with an <code>&lt;ul&gt;</code>{" "}
            element and they consist of :
          </p>
          <ul>
            <li>
              <strong>Main text: </strong> 1rem (16px) font size with
              1.25rem(20px) horizontal padding and 0.5rem(8px) vertical padding.
            </li>
            <li>
              <strong>Icon: </strong> 1.25rem (20px) width and height with
              0.5rem(8px) distance from the text.
            </li>
            <li>
              <strong>Assistive text: </strong> 0.875rem (14px) font size and
              0.25rem(4px) vertical distance from the main text.
            </li>
          </ul>
          <p>
            The width of the dropdowns can be adjusted by adding a width class
            to the <code>&lt;ul&gt;</code> element.
          </p>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The trigger button has an <code>aria-expanded</code> role with a
              true/false value, that allows vocalizing menu states (open or
              closed).
            </li>
            <li>
              Ιf the dropdown menu is used within a navigation menu the{" "}
              <code>aria-current=&quot;page&quot;</code> attribute should be
              assigned to the item corresponding to the active page.
            </li>
            <li>
              A <code>role=&quot;separator&quot;</code> role can be assigned to
              an empty <code>&lt;li&gt;</code> to separate the menu in sections.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default DropdownsPage
